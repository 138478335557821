<template>
  <div class="searchPage">
    <div class="search">
      <div class="logo">
        <van-icon name="arrow-left" @click="gopage" />
      </div>
      <div class="input">
        <van-field
          left-icon="search"
          v-model="value"
          placeholder="请输入搜索内容"
        />
      </div>
      <div @click="search" class="messageIcon">搜索</div>
    </div>
    <div class="hotsearch">
      <div class="top">
        <div class="title">
          热门搜索
          <img
            src="@/assets/all_Icon/drawable-xhdpi/icon_hot.png"
            class="hotimg"
          />
        </div>
        <div class="del"></div>
      </div>
      <div class="hotList">
        <div
          class="item"
          v-for="(item, index) in hotlist"
          @click="search(2, item.name)"
          :key="index"
        >
          {{ item.name }}
        </div>
      </div>
      <div v-if="searchHistoryList.length > 0">
        <div class="top">
          <div class="title">历史搜索</div>
          <div class="del">
            <van-icon @click="delHistory" name="delete-o" />
          </div>
        </div>
        <div class="hisList hotList">
          <div
            class="item"
            v-for="(item, index) in searchHistoryList"
            :key="index"
            @click="search(1, item)"
          >
            {{ item }}
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { theme7 } from "@/api/home.js";
export default {
  props: {},
  data() {
    return {
      value: "",
      hotlist: [],
      searchHistoryList: [],
      historyPath: "",
    };
  },
  created() {
    this.getHotList();
    this.searchHistoryList = window.localStorage.getItem("serchArr")
      ? JSON.parse(window.localStorage.getItem("serchArr"))
      : [];
  },
  beforeRouteEnter(to, form, next) {
    next((vm) => {
      if (form.path.indexOf("/searchresult") > -1) {
        vm._data.value = form.query.key;
      }
    });
  },
  methods: {
    changePage() {
      this.$emit("changepage");
    },
    gopage() {
      this.$router.go(-1);
    },
    getHotList() {
      theme7().then((res) => {
        this.hotlist = res.data.data;
      });
    },
    search(type, val) {
      if (type == 1) {
        this.$router.push({ path: "/searchresult", query: { search: val } });
      } else if (type == 2) {
        this.$router.push({ path: "/searchresult", query: { search: val } });
      } else {
        if (this.value == "") {
          this.$toast("请填写搜素条件");
          return;
        }
        let searchValue = this.searchHistoryList;
        if (this.searchHistoryList.length > 0) {
          searchValue.push(this.value);
          window.localStorage.setItem("serchArr", JSON.stringify(searchValue));
        } else {
          searchValue.push(this.value);
          window.localStorage.setItem("serchArr", JSON.stringify(searchValue));
        }
        this.$router.push({
          path: "/searchresult",
          query: { search: this.value },
        });
      }
    },
    delHistory() {
      window.localStorage.setItem("serchArr", []);
      this.searchHistoryList = [];
    },
  },
};
</script>

<style lang="less" scoped>
.searchPage {
  padding: 0 14px 30px;
  .search {
    display: flex;
    align-items: center;
    justify-content: space-between;
    margin: 18px 0 14px 0;
    .logo {
      .van-icon {
        font-size: 26px;
      }
    }
    .input {
      margin: 0 14px;
      width: 80%;
      .van-cell {
        margin: 0 4px;
        background: #f6f7f7;
        border-radius: 14px; /*no*/
      }
    }
    .messageIcon {
      width: 10%;
      position: relative;
      font-size: 14px;
    }
  }
  .hotsearch {
    .top {
      display: flex;
      justify-content: space-between;
      align-items: center;
      margin: 13px 0;
      .title {
        font-size: 16px;
        font-family: PingFangSC-Medium, PingFang SC;
        font-weight: 550;
        color: #141414;
        line-height: 23px;
        .hotimg {
          width: 12px;
          margin-left: 4px;
        }
      }
      .del {
        .van-icon {
          font-size: 16px;
        }
      }
    }
    .hotList {
      display: flex;
      align-items: center;
      flex-wrap: wrap;
      .item {
        background: #f1f3fb;
        border-radius: 500px;
        text-align: center;
        padding: 5px 8px;
        font-size: 14px;
        font-family: PingFangSC-Regular, PingFang SC;
        font-weight: 400;
        color: #333333;
        margin-right: 4px;
        margin-bottom: 13px;
      }
    }
    .hisList {
      .item {
        background: #f3f3f3;
      }
    }
  }
}
</style>
